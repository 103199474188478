import request from '@/utils/request'

// 动态咨询
export function dynamicInfoPage(data){
    return request({
        url:'/home/getNewsByType',
        method:'POST',
        data
    })
}

// 咨询详情
export function dynamicDetails(data){
    return request({
        url:'/home/getNewsById',
        method:'POST',
        data
    })
}