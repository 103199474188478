<template>
    <div class="banner-wrap">
<!--      :autoplay="false"-->
        <el-carousel height="540px" trigger="click" :interval="5000" arrow="never" class="carousel" >
            <el-carousel-item v-for="(item,k) in bannerList" :key="k" class="carousel-item">
                <img :src="baseURL+item.bannerPic"  style="width: 100%;height: 100%;object-fit: inherit"/>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {bannerList} from "@/api/home"
export default {
  name: '',
  data () {
    return {
        baseURL,
        bannerList:[
            // {url:require("@/assets/images/banner/banner1.png") },
            // {url:require("@/assets/images/banner/banner2.png") },
            // {url:require("@/assets/images/banner/banner3.png") },
        ]
    }
  },
  watch:{
    '$route':{
        immediate:true,
        handler(newValue){
            // console.log("newValue @@@@@@@@@@@ ",newValue);
            if(newValue.name == 'home'){
                this.getBannerList(1)
            }else if(newValue.name == 'DynamicInfo'){
                this.getBannerList(5)
            }else if(newValue.name == 'AboutUsDetails'){
                this.getBannerList(6)
            }
        },
    }
  },
  created () {
  },
  methods: {
    // 获取banner列表
    getBannerList(id){
        bannerList({bannerPage:id}).then(res => {
            // console.log("获取banner列表 ========= ",res);
            this.bannerList = res.data

        })
    },
  },
}
</script>
<style lang="less" scoped>
    .banner-wrap{
        //width: 1920px;
      width: 100%;
        // margin-top: 64px;
        .carousel-item{
            img{
                width:100%;
                height:100%;
                object-fit: cover;
            }
        }

        /deep/ .el-carousel__indicators {// 指示器
            bottom:10%;
            transform: unset;
            left: 18.5%;
            .el-carousel__button{
                width:40px;
                height:4px;
                background-color:rgba(81, 92, 102, 0.2);
                border-radius: 60px;
            }
        }
        /deep/ .el-carousel__indicators .is-active{// 指示器
            .el-carousel__button{
                width:40px;
                height:4px;
                background-color:rgba(81, 92, 102, 1);
                border-radius: 60px;
            }
        }
    }

</style>
