import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'
import AboutUsDetails from '../views/aboutUs/details.vue'
import ProductDetails from '../views/product/details.vue'
import SolutionDetails from '../views/solution/details.vue'
import DynamicInfo from '../views/dynamicInfo/index.vue'
import ProductList from '../views/product/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/aboutUsDetails',
    name: 'AboutUsDetails',
    component: AboutUsDetails
  },
  {//解决方案详情
    path: '/solutionDetails',
    name: 'SolutionDetails',
    component: SolutionDetails
  },
  {//动态资讯
    path: '/dynamicInfo',
    name: 'DynamicInfo',
    component: DynamicInfo
  },
  {//产品页面
    path: '/productList',
    name: 'ProductList',
    component: ProductList
  },
  {
    path:'/productDetails',
    name:'ProductDetails',
    component:ProductDetails
  },
  {//动态资讯详情
    path: '/dynamicDetails',
    name: 'DynamicDetails',
    component:()=>import('../views/dynamicInfo/details.vue'),
  },
   {//应用案例详情
    path: '/caseDetails',
    name: 'CaseDetails',
    component:()=>import('../views/case/details.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
