<template>
    <div class="standrad-product">
        <div class=""> <!-- 版心-->
            <div class="module-title">
                <div class="title">精准智慧农业标准产品</div>
                <div class="sub-title">{{homeTitles.hotProduct}}</div>
            </div>

            <vue-seamless-scroll :data="hotProductList" :class-option="defaultOption">
                <div class="list">
                    <div class="list-item" v-for="(item,k) in hotProductList" :key="k" @click="pageJumps(`/ProductDetails?id=${item.id}`)">
                        <div class="list-pic">
                            <img :src="baseURL+item.productPic" alt="">
                        </div>
                        <div class="list-content">
                            <div class="name">
                                <img :src="baseURL+item.productIcon" alt="">
                                <span>{{item.productName}}</span>
                            </div>
                            <!-- <div class="intro">{{item.productDesc}}</div> -->
                            <div class="intro" v-html="item.productDesc.replace(/\n|\r\n/g,'<br>').replace(/ /g,'&nbsp')"></div>
                            <div class="look-detal-btn" >查看详情</div>
                        </div>
                    </div>
                </div>
            </vue-seamless-scroll>
        </div>
    </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {hotProduct} from "@/api/home"
import vueSeamlessScroll from '@/components/vue-seamless-scroll'
export default {
    name: '',
    components:{vueSeamlessScroll},
    data () {
        return {
            baseURL,
            hotProductList:[],
        }
    },
    computed:{
        homeTitles(){
            return this.$store.state.homeTitles
        },

        defaultOption () {
            return {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 3, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction:2 , // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }      
    },
    created () {
    },
    mounted () {
        this.getHotProductList()
    },
    methods: {
        // 获取产品列表
        getHotProductList(){
            hotProduct().then(res=>{
                console.log(res,"热门产品信息")
                this.hotProductList=res.data
            })
        },

        pageJumps(path){
            this.$router.push({path})
        },
    },
}
</script>
<style lang="less" scoped>
.standrad-product{
    width: 100%;
    overflow: hidden;
    padding: 72px 0 97px 0;
    background: linear-gradient(180deg, rgba(48, 149, 242, 0.06) 0%, rgba(66, 180, 255, 0.06) 100%);
    .list{
        margin-top: 44px;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .list-item{
            width: 290px;
            height: 384px;
            margin-right: 20px;
           
            .list-pic{
                height: 150px;
                border-radius: 8px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .list-content{
                margin-top: -20px;
                height: 254px;
                background: #ffffff;
                border-radius: 8px;
                position: relative;
                z-index: 1;
                padding: 16px;
                box-sizing: border-box;
                .name{
                    display: flex;
                    align-items: center;
                    img{
                        width: 32px;
                        height: 32px;
                    }
                    span{
                        font-size: 20px;
                        font-weight: 500;
                        color: rgba(31, 35, 41, 1);
                        margin-left: 16px;
                    }
                }
                .intro{
                    margin-top: 18px;
                    font-size: 14px;
                    color: rgba(129, 136, 145, 1);
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 6; /* 这里是超出几行省略 */
                    overflow: hidden;
                }
                .look-detal-btn{
                    width: 258px;
                    font-size: 14px;
                    color: rgba(52, 156, 87, 1);
                    border-radius: 4px;
                    border: 1px solid rgba(52, 156, 87, 1);  
                    padding: 6px;
                    box-sizing: border-box;
                    text-align: center; 
                    position: absolute;
                    bottom: 16px;
                }
            }
             &:hover{
                box-shadow: 0px 32px 120px 0px rgba(148, 157, 166, 0.22);
                cursor: pointer;
                .look-detal-btn{
                    background: rgba(52, 156, 87, 1);
                    color: #ffffff;
                } 
            }
        }
    }
}

</style>
