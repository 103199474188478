<template>
  <div class="aboutUsDetails">
    <Banner style="width:1920px"/>
    <el-tabs v-model="activeName">
      <el-tab-pane label="公司简介" name="1">
        <div class="companyProfile">
          <div class="wrap">
            <div class="companyProfile_title">公司简介</div>
            <div class="companyProfile_content">
              <div>{{ companyInfo.proDesc }}</div>
              <!-- <div>成都比昂农科技股份有限公司是一家服务于农的国家高新技术企业，致力于成为全球领先的数字农业综合服务商，秉承“用科技改变传统农业，用服务缔造美好生活”的使命，利用人工智能、物联网、大数据、互联网、区块链等信息技术，聚焦数字农业核心技术闭环，精炼智能装备、软件平台、大数据应用三大业务体系，提供集数据采集、分析决策、精准执行与科学管理于一体的数字农业一站式综合解决方案，为农业农村农政体系数字化转型、农业生产数字化应用及科研数字化创新提供重要的装备技术支撑与数据服务。</div> -->
              <!-- <div>公司成立于2000年，总部坐落于成都，旗下拥有精准有限公司、为民蜀光有限公司等多家子公司，并在北京、重庆、黑龙江、辽宁、山东、四川、甘肃、湖北、安徽等地设有办事处，业务范围覆盖全国。成都比昂通过信息技术与农业专业、软件与硬件协同的双轮驱动战略，创新升级技术，研发、迭代200+智能硬件装备，联动物联网平台，构建农业生产全要素的智慧数据采集、管理决策系统，打造农业大脑；搭建省市级乡村大脑，落地多跨应用场景；开发数字乡村服务应用，建设农业产业大脑。截至目前，数字农业综合解决方案服务地图覆盖上万个乡镇，涵盖千余市县农业云平台、万余服务经营主体。 </div> -->
              <!-- <div>公司目前是全国农业技术推广服务中心全面战略合作单位，农业农村部耕地质量监测保护中心战略合作单位，四川省农业农村厅战略合作单位。目前有员工300余人，研发技术人员占比达40%。截至目前，公司已研发了70余种传感器和130多种智能装备，获得国家专利110项，产品软件著作权278项，国家标准1项，行业标准4项，团体标准3项。</div> -->
            </div>
          </div>
        </div>
        <div class="visionMission">
          <div class="visionMission_title">愿景使命</div>
          <div class="visionMission_content">农业实现智慧管理 农产品没有农残威胁</div>
          <!-- <div class="visionMission_content">坚持以科技改变农业、创新引领未来为己任</div> -->
        </div>
        <div class="DevelopmentCourse">
          <div class="wrap">
            <div class="DevelopmentCourse_title">发展历程</div>
            <div class="DevelopmentCourse_content">
              <el-carousel ref="DevelopmentCourse_carousel" height="513px" direction="vertical" :autoplay="false"
                           indicator-position="none">
                <el-carousel-item v-for="(item,index) in developmentHistoryList" :key="index">
                  <div class="title1">{{ item.year }}</div>
                  <div class="info">
                    <div class="info_item">{{ item.content }}</div>
                  </div>
                  <div class="title2">{{ Number(item.year) - 1 }}</div>
                </el-carousel-item>
              </el-carousel>
              <batterScroll :data="developmentHistoryList" @setActiveItem="setActiveItem"/>
            </div>
          </div>
        </div>
        <div class="technicalAdvantage">
          <div class="wrap">
            <div class="technicalAdvantage_title">技术优势</div>
            <div class="technicalAdvantage_content">
              <div class="technicalAdvantage_content_item">
                <img src="@/assets/images/aboutUs_details/ryzz.png" alt="">
                <div>
                  <div class="value">100+</div>
                  <div class="label">荣誉资质</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="technicalAdvantage_content_item">
                <img src="@/assets/images/aboutUs_details/fmzl.png" alt="">
                <div>
                  <div class="value">30+</div>
                  <div class="label">发明专利</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="technicalAdvantage_content_item">
                <img src="@/assets/images/aboutUs_details/rjzzq.png" alt="">
                <div>
                  <div class="value">50+</div>
                  <div class="label">软件著作权</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="technicalAdvantage_content_item">
                <img src="@/assets/images/aboutUs_details/hyjy.png" alt="">
                <div>
                  <div class="value">14年</div>
                  <div class="label">行业经验</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 企业合作伙伴 -->
        <div>
          <Partner/>
        </div>

        <div class="expertsTeam">
          <div class="expertsTeam_title">专家团队</div>
          <div class="expertsTeam_content">
            <vue-seamless-scroll :data="expertsList" :class-option="defaultOption">
              <div class="list">
                <div class="list_item" v-for="(item,index) in expertsList" :key="index"
                     :class="{'box_rolling' : isRolling == index}">
                  <div class="rollbox_front" @click="understandMore(index)">
                    <div class="img"
                         :style="{background:`url(${baseURL+item.expertPic}) `,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%'}"></div>
                    <div class="name">{{ item.expertName }}</div>
                    <div class="title">{{ item.expertDesc }}</div>
                    <div class="details">
                      <!-- {{ item.expertContent }} -->
                    </div>
                    <div class="btn">了解更多
                      <div></div>
                    </div>
                  </div>
                  <div class="rollbox_behind" @mouseleave="showFront">
                    <div class="details2">
                      <div v-html="item.expertContent.replace(/\n|\r\n/g,'<br>').replace(/ /g,'&nbsp')"></div>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
            <div class="changeBtn">
              <div class="changeBtn_item" @click="change(2)"><i class="el-icon-arrow-left"></i></div>
              <div class="changeBtn_item" @click="change(3)"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>
        </div>
        <div class="advantageService">
          <div class="advantageService_title">服务优势</div>
          <div class="advantageService_content">
            <div class="list">
              <div class="list_item">
                <div>
                  <div class="list_item_bg">
                    <img :src="baseURL+serviceAdvantageInfo.serviceOnePic" alt="">
                  </div>
                  <div class="list_item_title">
                    <div class="img"><img src="@/assets/images/aboutUs_details/fwysIcon1.png" alt=""></div>
                    {{ serviceAdvantageInfo.serviceOne }}
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div>
                  <div class="list_item_bg">
                    <img :src="baseURL+serviceAdvantageInfo.serviceTwoPic" alt="">
                  </div>
                  <div class="list_item_title">
                    <div class="img"><img src="@/assets/images/aboutUs_details/fwysIcon2.png" alt=""></div>
                    {{ serviceAdvantageInfo.serviceTwo }}
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div>
                  <div class="list_item_bg">
                    <img :src="baseURL+serviceAdvantageInfo.serviceThreePic" alt="">
                  </div>
                  <div class="list_item_title">
                    <div class="img"><img src="@/assets/images/aboutUs_details/fwysIcon3.png" alt=""></div>
                    {{ serviceAdvantageInfo.serviceThree }}
                  </div>
                </div>
              </div>
              <div class="list_item">
                <div>
                  <div class="list_item_bg">
                    <img :src="baseURL+serviceAdvantageInfo.serviceFourPic" alt="">
                  </div>
                  <div class="list_item_title">
                    <div class="img"><img src="@/assets/images/aboutUs_details/fwysIcon4.png" alt=""></div>
                    {{ serviceAdvantageInfo.serviceFour }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="honorAndQualification">
          <div class="honorAndQualification_title">荣誉资质</div>
          <div class="introduce">
            专注农业物联网、人工智能领域技术的研发，技术水平处于国内领先地位，技术与产品均得到行业的高度认可。目前累计申请国家专利、计算机软件著作权数十项。
          </div>
          <div class="honorAndQualification_content">
            <el-carousel ref="honorAndQualification_carousel" height="258px" :autoplay="false" arrow="never">
              <el-carousel-item v-for="(item,index) in honorPicList" :key="index">
                <div class="list">
                  <div class="list_item" v-for="value in item" :key="value.id">
                    <img :src="baseURL+value.picUrl" alt="">
                    <div>{{ value.picDesc }}</div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="officeAddress">
          <div class="officeAddress_title">服务地点</div>
          <div class="officeAddress_content">
            <baidu-map class="map" :center="{lng: 104.07156433784574, lat: 30.596920810513563}" :zoom="5"
                       :scroll-wheel-zoom="true" @click="getPoint">
              <bm-marker
                  v-for="(val,index) in officeLocationList" :key="'office'+index"
                  :position="{lng: val.lng, lat: val.lat}"
                  :dragging="false"
                  @click="infoWindowOpen(val,index)">
                <bm-info-window :show="val.show" @close="infoWindowClose(val)">
                  <div class="infoBox">
                    <div class="head">{{ val.comName }}</div>
                    <div class="info_item">服务电话：{{ val.workPhone }}</div>
                    <!-- <div class="info_item">生产中心电话：{{val.proPhone}}</div> -->
                    <div class="info_item">服务地址：{{ val.workAddr }}</div>
                  </div>
                </bm-info-window>
              </bm-marker>
            </baidu-map>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
//          发展历程            公司简介       专家团队     服务优势        服务地点        图片类型       图片列表
import {
  developmentHistory,
  companyIntroduce,
  experts,
  serviceAdvantage,
  officeLocation,
  byTypeGetImg,
  allPicList
} from '@/api/aboutUs'
import Banner from '@/components/Banner.vue'
import vueSeamlessScroll from '@/components/vue-seamless-scroll'
// import vueSeamlessScroll from 'vue-seamless-scroll'
import {baseURL} from '@/utils/request'
import BScroll from 'better-scroll';
import batterScroll from '@/components/batter-scroll'

import Partner from "@/views/home/components/partner.vue"

export default {
  name: 'aboutUs-details',
  components: {vueSeamlessScroll, Banner, batterScroll, Partner},
  data() {
    return {
      baseURL: baseURL,
      activeName: '1',
      indicatorIndex: 0,
      direction: 2,
      fwysIndex: 0,
      isRolling: null,
      developmentHistoryList: [],//发展历程列表
      companyInfo: {},//公司简介
      expertsList: [],//专家团队
      serviceAdvantageInfo: {},//服务优势
      honorPicList: [],//荣誉资质图片
      scroll: null,
      officeLocationList: [], // 服务地点


    }
  },
  computed: {
    defaultOption() {
      const expertsListLength = this.expertsList?.length || 6;
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: Math.min(expertsListLength, 6), // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: this.direction, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  watch: {
    '$route'(newValue) {
      console.log("newValue", newValue);
      this.scrollFn2()
    },
  },
  created() {
    // 获取发展历程列表
    this.getdevelopmentHistory()
    // 公司简介
    this.getcompanyIntroduce()
    // 专家团队
    this.getexperts()
    // 服务优势
    this.getserviceAdvantage()
    // 服务地点
    this.getOfficeLocation()
    // 图片列表
    this.getAllPicList()
  },
  mounted() {
    this.scrollFn()

    this.$nextTick(() => {
      let dev_wrapper = document.querySelector('.dev_wrapper')
      if (!this.scroll) {
        this.scroll = new BScroll(dev_wrapper, {
          scrollY: true,
          eventPassthrough: 'vertical',
        });
      } else {
        this.scroll.refresh()
      }

    })
  },
  methods: {
    scrollFn() {
      let module = this.$route.query.module
      if (module == 'gsjj') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 400
        })
      } else if (module == 'yjsm') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 900
        })
      } else if (module == 'fzlc') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 1500
        })
      } else if (module == 'jsys') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 2100
        })
      } else if (module == 'hzhb') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 2600
        })
      } else if (module == 'zjtd') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 2900
          // document.documentElement.scrollTop = 2600
        })
      } else if (module == 'fwys') {
        this.$nextTick(() => {
          console.log(document.documentElement.scrollTop, "document.documentElement.scrollTop ")
          document.documentElement.scrollTop = 3260
          // document.documentElement.scrollTop = 2800
        })
      } else if (module == 'ryzz') {
        setTimeout(() => {
          this.$nextTick(() => {
            document.documentElement.scrollTop = 3700
            // document.documentElement.scrollTop = 3400
          })
        }, 10)
      } else if (module == 'bgdd') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 4200
          // document.documentElement.scrollTop = 4000
        })
      }
    },
    scrollFn2() {
      let module = this.$route.query.module
      if (module == 'gsjj') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 400
        })
      } else if (module == 'yjsm') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 900
        })
      } else if (module == 'fzlc') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 1500
        })
      } else if (module == 'jsys') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 2100
        })
      } else if (module == 'hzhb') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 2600
        })
      } else if (module == 'zjtd') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 3100
          // document.documentElement.scrollTop = 2600
        })
      } else if (module == 'fwys') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 3900
          // document.documentElement.scrollTop = 3300
        })
      } else if (module == 'ryzz') {
        setTimeout(() => {
          this.$nextTick(() => {
            document.documentElement.scrollTop = 4500
            // document.documentElement.scrollTop = 3900
          })
        }, 10)
      } else if (module == 'bgdd') {
        this.$nextTick(() => {
          document.documentElement.scrollTop = 5000
          // document.documentElement.scrollTop = 4500
        })
      }
    },
    getPoint(e) {
      console.log(e.point);
    },
    setActiveItem(index) {
      this.$refs.DevelopmentCourse_carousel.setActiveItem(index)
      // this.indicatorIndex = index
    },
    change(num) {
      this.direction = num
    },
    infoWindowClose(item) {
      item.show = false;
    },
    infoWindowOpen(item, index) {
      item.show = true;
    },
    // 了解更多
    understandMore(index) {
      console.log("index", index);
      this.isRolling = index
    },
    showFront() {
      this.isRolling = null
      console.log("this.isRolling", this.isRolling);
    },
    // 获取发展历程列表
    getdevelopmentHistory() {
      developmentHistory({pageSize: 10000}).then(res => {
        console.log('res 获取发展历程列表', res);
        if (res.code == 200) {
          this.developmentHistoryList = res.rows
        }
      })
    },
    // 公司简介
    getcompanyIntroduce() {
      companyIntroduce().then(res => {
        console.log("res 公司简介", res);
        if (res.code == 200) {
          this.companyInfo = res.rows[0]
        }
      })
    },
    // 专家团队
    getexperts() {
      experts().then(res => {
        console.log("res 专家团队", res);
        if (res.code == 200) {
          this.expertsList = res.rows
        }
      })
    },
    // 服务优势
    getserviceAdvantage() {
      serviceAdvantage().then(res => {
        console.log('res 服务优势 公司概况', res);
        if (res.code == 200) {
          this.serviceAdvantageInfo = res.rows[0]
        }
      })
    },
    // 服务地点
    getOfficeLocation() {
      officeLocation().then(res => {
        console.log("res 服务地点", res);
        if (res.code == 200) {
          this.officeLocationList = res.data
          this.officeLocationList.forEach(item => {
            this.$set(item, 'show', false)
          });
        }
      })
    },
    // 图片列表
    getAllPicList() {
      allPicList().then(res => {
        console.log("rse 图片列表", res);
        if (res.code == 200) {
          let data = res.data[0].honorPicList
          for (let i = 0; i < data.length;) {
            this.honorPicList.push(data.slice(i, i += 4))
          }
          console.log("this.honorPicList", this.honorPicList);
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.aboutUsDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  /deep/ .el-tabs {
    .el-tabs__header {
      display: none;
      margin: 0;

      .el-tabs__nav-wrap::after {
        background: rgba(225, 233, 235, 1);
      }

      .el-tabs__nav-wrap {
        background: rgba(240, 246, 247, 1);
      }

      .el-tabs__nav-scroll {
        width: 1220px;
        padding: 0 0;
        box-sizing: border-box;
        margin: 0 auto;

        .el-tabs__item {
          height: 64px;
          line-height: 64px;
          font-size: 16px;
          color: rgba(32, 37, 45, 1);

          &.is-active {
            color: rgba(42, 130, 228, 1);
          }
        }

        .el-tabs__active-bar {
          background: rgba(42, 130, 228, 1);
        }

      }
    }

    .el-tabs__content {
      .el-tab-pane {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div {
          width: 1920px;
        }

        // 公司简介
        .companyProfile {
          background: rgba(240, 245, 250, 1);

          .wrap {
            padding: 100px 0 100px 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            .companyProfile_title {
              font-size: 36px;
              color: rgba(31, 35, 41, 1);
              margin: 0 0 65px 0;
            }

            .companyProfile_content {
              font-size: 23.5px;
              color: rgba(39, 41, 43, 1);

              > div {
                margin: 0 0 30px 0;
              }

              > div:last-child {
                margin: 0;
              }
            }
          }
        }

        // 愿景使命
        .visionMission {
          background: #F7F9FF;
          display: flex;
          flex-direction: column;
          align-items: center;

          .visionMission_title {
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
            height: 189px;
            line-height: 189px;
            text-align: center;
          }

          .visionMission_content {
            background: url(https://img.js.design/assets/img/63d718713f6ab1dd43953723.png#817d7eee49bbdc2cc773b12ed3b936fa) no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 44px;
            height: 460px;
            line-height: 460px;
            text-align: center;
            width: 100%;
          }
        }

        // 发展历程
        .DevelopmentCourse {
          height: 635px;
          background: rgba(235, 241, 247, 1);

          .wrap {
            padding: 72px 0 0 0;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            .DevelopmentCourse_title {
              font-size: 36px;
              color: rgba(31, 35, 41, 1);
            }

            .DevelopmentCourse_content {
              width: 100%;
              position: relative;

              .el-carousel {
                .el-carousel__item {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                }

                .title1 {
                  font-size: 44px;
                  color: rgba(52, 156, 87, 1);
                  margin: 44px 0 33px 0;
                  font-weight: bold;
                }

                .info {
                  font-size: 24px;
                  color: rgba(39, 41, 43, 1);

                  .info_item {
                    margin: 0 0 16px 0;
                    width: 890px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 8;
                    overflow: hidden;
                  }
                }

                .title2 {
                  font-size: 44px;
                  font-weight: bold;
                  margin: 0 0 33px 0;
                  background-image: -webkit-linear-gradient(top, rgba(150, 156, 168, 1), rgba(255, 255, 255, 0) 80%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }
            }
          }
        }

        // 技术优势
        .technicalAdvantage {
          background: #F7F9FF;
          padding: 100px 0 109px 0;

          .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;

            .technicalAdvantage_title {
              font-size: 36px;
              color: rgba(31, 35, 41, 1);
            }

            .technicalAdvantage_content {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 73px 0 0 0;

              .technicalAdvantage_content_item {
                display: flex;
                align-items: center;

                > img {
                  width: 80px;
                  height: 80px;
                  margin: 0 25px 0 0;
                }

                > div {
                  display: flex;
                  flex-direction: column;
                  align-items: center;

                  .value {
                    font-size: 40px;
                    color: rgba(42, 130, 228, 1);
                    margin: 0 0 8px 0;
                  }

                  .label {
                    font-size: 24px;
                    color: rgba(31, 35, 41, 1);
                  }
                }
              }

              .line {
                height: 77px;
                border-left: 1px dashed rgba(165, 168, 175, 1);
                margin: 0 48px;
              }
            }
          }
        }

        // 专家团队
        .expertsTeam {
          background: rgba(235, 241, 247, 1);
          padding: 72px 0 76px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;

          .expertsTeam_title {
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
          }

          .expertsTeam_content {
            width: 100%;
            margin: 67px 0 0 0;

            .list {
              display: flex;

              .list_item {
                position: relative;
                margin: 0 20px 0 0;

                > div {
                  width: 285px;
                  height: 411px;
                  border-radius: 8px;
                  background: rgba(255, 255, 255, 1);
                  box-shadow: 0px 32px 120px 0px rgba(148, 157, 166, 0.12);
                  padding: 16px;
                  box-sizing: border-box;
                  cursor: pointer;
                  overflow: hidden;
                  transition: all 0.1s;
                  // position: relative;
                  .img {
                    width: 100%;
                    height: 224px;
                    border-radius: 4px;
                    overflow: hidden;
                    transition: height .6s;
                  }

                  .name {
                    font-size: 20px;
                    color: rgba(31, 35, 41, 1);
                    margin: 12px 0 8px 0;
                  }

                  .title {
                    font-size: 15px;
                    color: rgba(31, 35, 41, .6);
                  }

                  // .details{
                  //     height: 50px;
                  //     font-size: 12px;
                  //     color: rgba(31, 35, 41, .4);
                  //     display: -webkit-box;
                  //     -webkit-box-orient: vertical;
                  //     -webkit-line-clamp: 3; /*超出几行后省略号*/
                  //     overflow: hidden;
                  //     margin: 8px 0;
                  // }
                  .btn {
                    font-size: 14px;
                    color: rgba(31, 35, 41, 1);
                    display: flex;
                    align-items: center;
                    position: absolute;
                    bottom: -20px;
                    transition: all 0.5s;
                    // margin: 50px 0 0 0;
                    div {
                      width: 25px;
                      border-top: 1px solid rgba(31, 35, 41, 1);
                      margin: 0 0 0 12px;
                    }
                  }

                  &:hover {
                    background: rgba(243, 249, 251, 1);

                    .img {
                      height: 174px;
                    }

                    .btn {
                      bottom: 10px;
                      color: rgba(52, 156, 87, 1);

                      div {
                        border-top: 1px solid rgba(52, 156, 87, 1);
                      }
                    }
                  }
                }
              }

              .rollbox_behind {
                // transform: rotateY(180deg);
                visibility: hidden; //元素不可见，但占据空间
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                display: flex;
                flex-direction: column;

                .details2 {
                  font-size: 12px;
                  color: rgba(31, 35, 41, .4);
                  margin: 8px 0;
                  flex: 1;

                  div {
                    font-size: 14px;
                  }

                  overflow-y: scroll;
                  overflow-x: hidden;

                  &::-webkit-scrollbar {
                    width: 3px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    background-color: rgba(52, 156, 87, 0.28);
                  }

                  &::-webkit-scrollbar-thumb {
                    width: 6px !important;
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background-color: rgba(52, 156, 87, 0.82);
                  }
                }
              }

              .box_rolling {
                .rollbox_front {
                  // transform: rotateY(180deg);
                  visibility: hidden;
                }

                .rollbox_behind {
                  // transform: rotateY(360deg);
                  visibility: visible;
                }
              }
            }

            .changeBtn {
              display: flex;
              justify-content: center;
              margin: 40px 0 0 0;

              .changeBtn_item {
                width: 32px;
                height: 32px;
                border-radius: 4px;
                background: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                i {
                  color: rgba(51, 51, 51, 0.4);
                  font-weight: bolder;
                }

                &:hover {
                  background: rgba(52, 156, 87, 1);

                  i {
                    color: #fff;
                  }
                }
              }

              .changeBtn_item:first-child {
                margin: 0 20px 0 0;
              }
            }
          }
        }

        // 服务优势
        .advantageService {
          background: rgba(81, 89, 98, .3);
          padding: 72px 0 100px 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          .advantageService_title {
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
            margin: 0 0 67px 0;
          }

          .advantageService_content {
            width: 1220px;

            .list {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;

              .list_item {
                margin: 0 0 20px 0;
                width: 600px;
                height: 139px;
                border-radius: 8px;
                overflow: hidden;
                background: rgba(81, 89, 98, 1);
                position: relative;
                transition: all .8s;

                &:after {
                  content: ' ';
                  position: absolute;
                  z-index: -1;
                  left: 0;
                  top: 0;
                  pointer-events: none;
                  width: 600px;
                  height: 139px;
                  background: url('@/assets/images/aboutUs_details/fwysItemBg.png') no-repeat;
                  background-size: 100% 100%;
                  transition: all .8s;
                }

                &:hover:after {
                  z-index: 10;
                }

                > div {
                  width: 100%;
                  box-sizing: border-box;
                  position: absolute;
                  z-index: 11;

                  .list_item_bg {
                    width: 600px;
                    height: 139px;

                    > img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }

                  }

                  .list_item_title {
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    font-size: 20px;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    position: absolute;
                    top: 0;
                    left: 0;

                    .img {
                      width: 32px;
                      height: 32px;
                      background: rgba(98, 113, 130, 1);
                      border-radius: 50%;
                      margin: 0 12px 0 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      transition: all .8s;
                    }
                  }

                  > div {
                    color: #fff;
                    font-size: 14px;
                  }

                  > div:not(:first-child) {
                    padding: 0 0 0 44px;
                    margin: 0 0 2px 0;
                  }

                  &:hover {
                    .img {
                      background: rgba(52, 156, 87, 1);
                    }
                  }

                }
              }
            }
          }
        }

        // 荣誉资质
        .honorAndQualification {
          padding: 72px 0 72px 0;
          display: flex;
          flex-direction: column;
          align-items: center;

          .honorAndQualification_title {
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
            margin: 0 0 16px 0;
          }

          .introduce {
            font-size: 16px;
            color: rgba(31, 35, 41, .8);
            margin: 0 0 44px 0;
          }

          .honorAndQualification_content {
            width: 1220px;

            .el-carousel {
              .list {
                display: flex;
                // justify-content: space-between;
                .list_item {
                  width: 290px;
                  height: 218px;
                  padding: 16px 16px 0 16px;
                  border-radius: 8px;
                  background: rgba(255, 255, 255, 1);
                  border: 1px solid rgba(0, 0, 0, 0.15);
                  box-sizing: border-box;
                  margin: 0 20px 0 0;

                  img {
                    margin: 0 0 12px 0;
                    width: 258px;
                    height: 160px;
                    vertical-align: middle;
                  }

                  > div {
                    font-size: 14px;
                    color: rgba(31, 35, 41, 1);
                    text-align: center;
                  }
                }

                .list_item:last-child {
                  margin: 0;
                }
              }

              .el-carousel__indicators {
                .el-carousel__indicator {
                  .el-carousel__button {
                    background: rgba(26, 29, 31, .2);
                    height: 4px;
                    width: 20px;
                  }

                  &.is-active {
                    .el-carousel__button {
                      width: 40px !important;
                      height: 4px;
                      background: rgba(26, 29, 31, .4);
                    }
                  }
                }
              }
            }
          }
        }

        // 服务地点
        .officeAddress {

          display: flex;
          flex-direction: column;
          align-items: center;

          .officeAddress_title {
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
            margin: 0 0 64px 0;
          }

          .officeAddress_content {
            width: 100%;

            .map {
              height: 600px;

              .infoBox {
                width: 400px;
                height: 130px;

                .head {
                  font-size: 20px;
                  border-bottom: 1px solid rgba(204, 204, 204, 1);
                  padding: 0 0 12px 0;
                  margin: 0 0 20px 0;
                }

                .info_item {
                  font-size: 16px;
                  color: rgba(0, 0, 0, .6);
                  margin: 0 0 12px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
