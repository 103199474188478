import axios from 'axios'
// import axios from '@/utils/axios'
import { MessageBox, Message } from 'element-ui'

// create an axios instance
export const baseURL="https://accurate-api.bigdata5s.com"
// export const baseURL="http://192.168.0.76:22600/api"
// export const baseURL='/gm-api'
const service = axios.create({
    baseURL,
    withCredentials: true, // send cookies when cross-domain requests  跨域请求时发送cookie
    timeout: 50000 // request timeout  请求超时
})

// request interceptor  请求拦截器
// service.interceptors.request.use(config => {

//     config.headers['Authorization'] = config.headers['Authorization'] ? config.headers['Authorization'] : 'Bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjBjMzk1YTU0LTc2NTgtNGRlZS1iYmEwLWMxYzJhNzNiYWVlZSJ9.yMZUoHWoRTmosVRtFYkdd8TI6MF7KnCogmNWpT07mY1uezVUdyDud8k0E3UuXNYSKh1g6qdjcLblO7_0qgRVNA'
//     config.headers.post = { 'Content-Type': 'application/json' }
  
//   return config
// },error => {
//   // do something with request error
//   console.log(error) // for debug
//   return Promise.reject(error)
// }
// )

// response interceptor  响应拦截器
service.interceptors.response.use(
 
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.  如果自定义代码不是20000，就会被判定为错误。
    if (res.code !== 200 && res.code !== 500) {
          Message({
            message: res.msg || 'Error',
            type: 'error',
            duration: 5 * 1000
          })

      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      if(res.code == 500){
        // Message({
        //   message: res.msg || 'Error',
        //   type: 'error',
        //   duration: 5 * 1000
        // })
      }
      return res
    }
  },
  error => {
    // console.log(error)
    // console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
