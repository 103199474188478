<template>
    <div class="solutionDetails" >
        <div class="banner ">
            <img :src="baseURL+solutionInfo.solutionBanner" alt="">
        </div>

        <div class="module" v-for="(item,index) in characterList" :key="index">
            <div class="module_title" v-if="index==0">{{solutionInfo.solutionName }}</div>
            <div class="module_content wrap">
                <!-- <div :id="'scroll'+index" class="module_content_img module_content_img_left" v-if="item.imgPosition == 'left'"> -->
                <div :id="'scroll'+index" class="module_content_img">
                    <img  :src="baseURL+item.pic" alt="">
                </div>
                <div class="module_content_text">
                    <div class="title">{{ item.name }}</div>
                    <div class="info">{{ item.characterDesc }}</div>
                </div>
                <!-- <div :id="'scroll'+index" class="module_content_img module_content_img_right" v-if="item.imgPosition == 'right'">
                    <img  :src="item.picUrl" alt="">
                </div> -->
            </div>
        </div>

        <!-- 推荐方案 -->
        <div class="recommendScheme">
            <div class="recommendScheme_head">
                <div class="title">推荐方案</div>
                <div class="introduce">为了更好地产品体验，我们为您精选了相关产品</div>
            </div>
            <div class="recommendScheme_content wrap">
                <el-carousel :interval="5000" height="435px"  arrow="always" indicator-position="outside">
                    <el-carousel-item v-for="(item,k) in hotSolutionList" :key="k">
                        <div class="box">
                            <div class="box_left">
                                <img :src="baseURL+item.solutionPic" alt="">
                            </div>
                            <div class="box_right">
                                <div class="scheme_title">{{item.solutionName}}</div>
                                <div class="scheme_content">{{item.solutionDesc}}</div>
                                <el-button class="viewMore" type="text" @click="pageJumps(`/solutionDetails?id=${item.id}`)">查看更多 <i class="el-icon-arrow-right"></i></el-button>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <contactUs />
    </div>
</template>

<script>
import {baseURL} from "@/utils/request"
import contactUs from '@/components/contactUs.vue'
import {solutionDetails,hotSolution} from '@/api/solution'
    export default {
        name:'solutionDetails',
        components:{
            contactUs,
        },
        data(){
            return{
                baseURL,

                id:"",
                solutionInfo:{},
                characterList:[],

                hotSolutionList:[],  // 热门解决方案列表
            }
        },
        watch:{
            $route: {
                handler() {
                    this.id = this.$route.query.id;
                    this.getSolutionDeatils()
                },
                deep: true,
            }
        },

        created(){
            this.id=this.$route.query.id
        },

        updated(){
            this.characterList.forEach((val,index) => {
                if(index == 0){
                    val.scrollTop = 300
                }else{
                    val.scrollTop = index*700+300
                }
            })
            window.addEventListener('scroll',() => {
                this.scrollTop = document.documentElement.scrollTop / this.$store.state.scale
                this.characterList.forEach((val,index) => {
                    if(val.scrollTop && this.scrollTop >= val.scrollTop){
                        if(document.querySelector(`#scroll${index}`) && !document.querySelector(`#scroll${index}`).className.includes('active')){
                            document.querySelector(`#scroll${index}`).className = `module_content_img active module_content_img_${val.imgPosition}`
                        }
                        // console.log("document.querySelector(`#scroll${index}`).className",document.querySelector(`#scroll${index}`).className);
                    }
                })
            })
        },

        mounted(){
            this.getSolutionDeatils()
            this.getHotSolutionList()
        },
        methods:{
            // 根据id获取解决方案详情
            getSolutionDeatils(){
                solutionDetails(this.id).then(res=>{
                    this.solutionInfo=res.data
                    this.characterList=res.data.characterList
                })
            },

            // 获取热门解决方案
            getHotSolutionList(){
                let para={isHot:1}
                hotSolution(para).then(res=>{
                    this.hotSolutionList=res.rows.slice(0,3)
                })
            },

            pageJumps(path){
                this.$router.push({path},() => {})
            },
        }
    }
</script>

<style lang="less" scoped>
.solutionDetails{
    padding: 64px 0 0 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    >div{
        // width: 1920px;
    }
    .banner{
        height: 540px;
        width: 1920px;
        // background: url('@/assets/images/slution/banner.png') no-repeat;
        // background-size:  100% 100%;
        >img{
            width: 100%;
            height: 100%;
        }
    }
    .title{
        font-size: 28px;
        font-weight: bold;
        color: rgba(31, 35, 41, 1);
        margin: 0 0 12px 0;
    }
    .info{
        font-size: 16px;
        color: rgba(0, 0, 0, .5);
        width: 490px;
    }
    .detail-box{

    }

    .module{
        width: 1920px;
        height: 807px;
        background: #fff;
        padding: 72px 0 0 0;
        box-sizing: border-box;
        &:nth-child(2n){
            background: #F0F2F7;
            .module_content{
                flex-direction:row-reverse;
                .module_content_img{
                    transform: translateX(150%);
                    &.active{
                        opacity: 1;
                        transform: translateX(0%)
                    }
                }
            }
        }
        .module_title{
            text-align: center;
            font-size: 36px;
            color: rgba(31, 35, 41, 1);
            margin: 0 0 65px 0;
        }
        .module_content{
            display: flex;
            justify-content: space-between;
            .module_content_text{
                margin: 153px 0 0 0;
            }
            .module_content_img{
                width: 650px;
                height: 520px;
                border-radius: 12px;
                background: rgba(240, 240, 240, 1);
                box-shadow: 0px 0px 24px 0px rgba(32, 37, 45, 0.06);
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;
                transition: all 1s;
                transform: translateX(-150%);
                img{
                    width: 100%;
                    height: 100%;
                }
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }

             /** .module_content_img_left{
                transform: translateX(-150%);
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }
            .module_content_img_right{
                transform: translateX(150%);
                &.active{
                    opacity: 1;
                    transform: translateX(0%)
                }
            }*/

        }
    }
    // .module:not(:nth-child(1)){
    //     height: 720px;
    // }
    .recommendScheme{
        width: 1920px;
        height: 807px;
        background: url(@/assets/images/slution/tjfaBg.png) no-repeat;
        background-size: 100% 100%;
        padding: 73px 0 0 0;
        box-sizing: border-box;
        .recommendScheme_head{
            margin: 0 0 44px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            .title{
                color: #fff;
                font-size: 36px;
                margin: 0 0 16px 0;
            }
            .introduce{
                font-size: 16px;
                color: rgba(255, 255, 255, .8);
            }
        }
        .recommendScheme_content{
            /deep/.el-carousel{
                .el-carousel__container{
                    .el-carousel__item {
                        // padding: 0 80px;
                        // box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .box{
                            width: 966px;
                            height: 435px;
                            background: #fff;
                            border-radius: 8px;
                            overflow: hidden;
                            display: flex;
                            // transform: scale(.6);
                            .box_left{
                                height: 435px;
                                width: 614px;
                                border-radius: 8px;
                                background: rgba(120, 134, 150, 1);
                                img{
                                    width: 614px;
                                    height: 435px;
                                }
                            }
                            .box_right{
                                flex: 1;
                                padding: 89px 50px 0 50px;
                                position: relative;
                                .scheme_title{
                                    font-size: 20px;
                                    color: rgba(31, 35, 41, 1);
                                }
                                .scheme_content{
                                    color: rgba(0, 0, 0, .5);
                                    margin: 24px 0 0 0;
                                    font-size: 14px;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 8; /*超出几行后省略号*/
                                    overflow: hidden;
                                }
                                .viewMore{
                                    position: absolute;
                                    right: 60px;
                                    bottom: 70px;
                                    padding: 0;
                                    span{
                                        color: rgba(52, 156, 87, 1);
                                    }
                                }
                            }
                        }
                    }
                    .el-carousel__arrow{
                        background: rgba(255, 255, 255, .2);
                        i{
                            color: #000;
                            font-weight: bold;
                            font-size: 18px;
                        }
                        &:hover{
                            background: rgba(255, 255, 255, .6);
                        }
                    }
                    .el-carousel__arrow--left{
                        left: 0;
                    }
                    .el-carousel__arrow--right{
                        right: 0;
                    }
                }
                .el-carousel__indicators{
                    margin: 70px 0 0 0;
                    >li{
                        padding: 0px 10px;
                        button{
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}
</style>
