<template>
    <div class="science-technology-help">
        <div class="wrap"> <!-- 版心-->
            <div class="module-title">
                <div class="title">众多客户的信赖之选，让科技助力生产</div>
                <div class="sub-title">{{homeTitles.classicCases}}</div>
            </div>

        
            <div class="main-content">
                <!-- 切换按钮 -->
                <div class="switchover" v-if="list.length>1">
                    <div v-for="(item,index) in list" 
                        :key="index" 
                        class="bar" 
                        :class="{'bar-active' : idx == index}"
                        @mouseover="mouseOver(index)">
                    </div>
                </div>

                <div class="left" v-if="list.length > 0" >
                    <!-- <img v-if="list.length > 0" :src="baseURL + list[idx].casePic" alt=""> -->
                    <video v-if="list[idx].video" :src="baseURL + list[idx].video" autoplay loop controls="controls"></video>
                    <img  v-else :src="baseURL + list[idx].casePic" alt="">
                </div>

                <div class="right" v-if="list.length > 0" @click="pageJumps(`/caseDetails?id=${list[idx].id}`)">
                    <div class="label-list" >
                        <div class="label-item" v-for="(val,i) in list[idx].tag.split(',')" :key="i">{{val}}</div>
                    </div>
                    <div class="name">{{list[idx].caseName}}</div>
                    <div class="desc">{{list[idx].caseDesc}}</div>
                    <div class="feature-list" v-if="list[idx].characteristic">
                        <div class="feature-item" v-for="(item,index) in list[idx].characteristic.split(',')" :key="index">
                            <img src="@/assets/images/home/tick_icon.png" alt="">
                            <span>{{item}}</span>
                        </div>
                    </div>
                    <div class="realize-btn">了解详情<i class="el-icon-arrow-right"></i></div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import {baseURL} from "@/utils/request"
import {hotCase} from "@/api/home"
export default {
    name: '',
    data () {
        return {
            baseURL,
            idx:0,
            list:[]
        }
    },
        computed:{
            homeTitles(){
                return this.$store.state.homeTitles
            }
        },
    created () {
    },
    mounted () {
        this.getHotCaseList()
    },
    methods: {
        mouseOver(val){
            this.idx=val
        },

        // 获取经典案例列表
        getHotCaseList(){
            hotCase({pageSize:4,pageNum:1}).then(res=>{
                console.log(res,"经典案例首页")
                this.list = res.data.slice(0,4)
            })
        },

        pageJumps(path){
            this.$router.push({path})
        },
    },
}
</script>
<style lang="less" scoped>
.science-technology-help{
    padding: 72px 0;
    .main-content{
        margin-top: 44px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        position: relative;
        .switchover{
            position: absolute;
            top: 32px;
            right:20px;
            display: flex;
            z-index: 22;
            .bar{
                width: 20px;
                height: 4px;
                border-radius: 60px;
                background: rgba(26, 29, 31, 0.2);
                margin-left: 8px;
                cursor: pointer;
                transition: all 0.5s;
            }
            .bar-active{
                width: 40px;
                background: rgba(26, 29, 31, 0.4);
            }
        }

        .left{
            width: 479px;
            height: 373px;
            cursor: pointer;
            img{
                width: 100%;
                height: 100%;
            }
            video{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .right{
            flex: 1;
            background: rgba(255, 255, 255, 1);
            padding: 20px;
            cursor: pointer;
            .label-list{
                display: flex;
                .label-item{
                    padding: 4px 16px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 1);
                    background: rgba(255, 141, 26, 1);
                    margin-right: 12px;
                    border-radius: 4px;
                }
            }
            .name{
                margin-top: 18px;
                font-size: 24px;
                font-weight: 600;
                color: rgba(31, 35, 41, 1);
            }
            .desc{
                margin-top: 13px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(31, 35, 41, 0.8);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
            }
            .feature-list{
                margin-top: 25px;
                padding: 16px;
                border-radius: 4px;
                background: rgba(230, 240, 242, 1);
                .feature-item{
                    display: flex;
                    align-items: center;
                    img{
                        width: 12px;
                    }
                    span{
                        margin-left: 8px;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(31, 35, 41, 0.8);
                    }
                    &:not(:last-child){
                        margin-bottom: 17px;
                    }
                }
            }
            .realize-btn{
                margin-top: 16px;
                font-size: 14px;
                // font-weight: 600;
                color: rgba(52, 156, 87, 1);
                text-align: right;
                cursor: pointer;
                i{
                    margin-left: 8px;
                    font-size: 12px;
                    font-weight: normal;
                }
            }
        }
    }
}

</style>
