<template>
    <div class="header">
        <div class="wrap">
            <div class="logo" @click="pageJumps('/')">
                <img src="@/assets/images/logo.png" alt="">
            </div>

            <div class="contact-number">
                <div class="box">
                    <img src="@/assets/images/telphone.png" alt="">
                    <div>
                        <span class="lable">售前咨询：</span>
                        <span class="number">028-87077178</span>
                    </div>
                </div>
                <div class="box">
                    <img src="@/assets/images/telphone.png" alt="">
                    <div>
                        <span class="lable">售后服务 :</span>
                        <span class="number">13228103000</span>
                    </div>
                </div>
            </div>

            <div class="menu">
                <div class="dropdown" :class="{active : isShowNavIndex == 1}" @mouseenter="mEnter(1)" @mouseleave="mLeave">
                    <!-- 标题 -->
                    <div class="dropdown-title" @click="pageJumps('/ProductList')">
                        <span>产品</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>
                    <div class="dropdown-content" style="height:800px">
                        <div class="dropdown-content-box product-menu">
                            <div class="product-left">
                                <div @mouseover="mouseOver(1)" :class="{active : proCateIdx == 1}">硬件产品</div>
                                <div @mouseover="mouseOver(2)" :class="{active : proCateIdx == 2}">软件产品</div>
                            </div>
                            <!-- 软件产品排版-->
                            <div class="product-soft" v-if="proCateIdx==2">
                                <div class="product-list">
                                    <div class="product-list-item" v-for="(item,k) in productList" :key="k" @click="pageJumps(`/ProductDetails?id=${item.id}&type=2`)">
                                        <img :src="baseURL + item.productIcon" alt="">
                                        <div class="main">
                                            <div class="name">{{ item.productName }}</div>
                                            <div class="desc">{{ item.subtitle }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- 硬件产品排版 -->
                            <div class="product-hard" v-if="proCateIdx==1">
                                <div class="hard-items" v-for="item in productList" :key="item.id">
                                    <div class="label">{{ item.productName }}</div>
                                    <div class="hard-pro" v-for="(item1,k1) in item.productList" :key="k1" @click="pageJumps(`/ProductDetails?id=${item1.id}&type=1`)">
                                        <div class="name">{{ item1.productName }}</div>
                                        <div class="intro">{{ item1.subtitle }}</div>
                                    </div>
                                    <!-- <div v-for="k in 40" :key="k">我按手印回复丢说这是否</div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown" :class="{active : isShowNavIndex == 2}" @mouseenter="mEnter(2)" @mouseleave="mLeave">
                    <!-- 标题 -->
                    <div class="dropdown-title">
                        <span>解决方案</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>

                    <div class="dropdown-content" style="height:800px">
                        <div class="dropdown-content-box solution-menu">

                            <div class="left-box"></div>
                            <div class="right-box"></div>

                            <div class="text-wrap">
                                <div class="text-left">
                                    <div class="label">行业解决方案</div>
                                    <ul class="solution-list">
                                        <li v-for="(item,index) in solutionsList" :key="item.id" @click="pageJumps(`/solutionDetails?id=${item.id}`)">
                                            <div class="serial">{{ index + 1 }}</div>
                                            <div class="name">{{ item.solutionName }}</div>
                                            <i class="el-icon-arrow-right"></i>
                                        </li>
                                    </ul>

                                    <div class="pic-text" v-if="recommendSolution">
                                        <div class="name">{{ recommendSolution.solutionName }}</div>
                                        <div class="desc">{{ recommendSolution.solutionDesc }}</div>
                                        <div class="new">New</div>
                                        <div class="pic"><img :src="baseURL+recommendSolution.solutionPic" alt=""></div>
                                        <div class="detail-btn" @click="pageJumps(`/solutionDetails?id=${recommendSolution.id}`)">
                                            <span>查看详情</span>
                                            <i class="el-icon-arrow-right"></i>
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right">
                                    <div>
                                        <div class="label">应用案例</div>
                                        <ul class="case-list" v-if="caseList.length">
                                            <div class="item" v-for="(item,k) in caseList" :key="k" @click="pageJumps(`/caseDetails?id=${item.id}`)">
                                                <img :src="baseURL+item.casePic" alt="">
                                                <div class="main">
                                                    <div class="name">{{ item.caseName }}</div>
                                                    <!-- <div class="desc">{{ item.caseDesc }}</div> -->
                                                </div>
                                            </div>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown" :class="{active : isShowNavIndex == 3}" @mouseenter="mEnter(3)" @mouseleave="mLeave">
                    <!-- 标题 -->
                    <div class="dropdown-title">
                        <span>动态资讯</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>

                    <div class="dropdown-content">
                        <div class="dropdown-content-box dynamic-menu">
                            <div class="menu-item" v-for="item in dynamicInfoList" :key="item.id">
                                <div class="label">{{ item.name }}</div>
                                <ul class="list" >
                                    <li v-for="k in item.newList" :key="k.id" @click="pageJumps(`/dynamicDetails?id=${k.id}&typeId=${item.id}`)">{{ k.newsTitle }}</li>
                                </ul>
                                <div class="look-more"  @click="pageJumps(`/dynamicInfo?id=${item.id}`)">查看更多 ></div>
                            </div>
                            <div class="pic-text" v-if="recommendInfo.id" >
                                <div class="name">{{ recommendInfo.newsTitle }}</div>
                                <div class="desc">{{ recommendInfo.newsDesc }}</div>
                                <div class="new">New</div>
                                <div class="pic"><img :src="baseURL + recommendInfo.newsPic" alt=""></div>
                                <div class="detail-btn" @click="pageJumps(`/dynamicDetails?id=${recommendInfo.id}&typeId=${dynamicInfoList[0].id}`)">
                                    <span>查看详情</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dropdown" :class="{active : isShowNavIndex == 4}" @mouseenter="mEnter(4)" @mouseleave="mLeave">
                    <!-- 标题 -->
                    <div class="dropdown-title" >
                        <span>关于我们</span>
                        <i class="el-icon-arrow-down"></i>
                    </div>

                    <div class="dropdown-content dropdown-content2">
                        <div class="dropdown-content-box aboutUs-menu">
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=gsjj')">
                                <img src="@/assets/images/aboutUs/gsjj.png" alt="">
                                <span>公司简介</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=yjsm')">
                                <img src="@/assets/images/aboutUs/yjsm.png" alt="">
                                <span>愿景使命</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=fzlc')">
                                <img src="@/assets/images/aboutUs/fzlc.png" alt="">
                                <span>发展历程</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=jsys')">
                                <img src="@/assets/images/aboutUs/jsys.png" alt="">
                                <span>技术优势</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=hzhb')">
                                <img src="@/assets/images/aboutUs/zjtd.png" alt="">
                                <span>合作伙伴</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=zjtd')">
                                <img src="@/assets/images/aboutUs/zjtd.png" alt="">
                                <span>专家团队</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=fwys')">
                                <img src="@/assets/images/aboutUs/fwys.png" alt="">
                                <span>服务优势</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=ryzz')">
                                <img src="@/assets/images/aboutUs/ryzz.png" alt="">
                                <span>荣誉资质</span>
                            </div>
                            <div class="aboutUs-menu-item" @click="pageJumps('/aboutUsDetails?module=bgdd')">
                                <img src="@/assets/images/aboutUs/bgdd.png" alt="">
                                <span>服务地点</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>
<script>
//      应用案例       解决方案    产品列表     动态咨询     解决方案重点推荐
import {CaseList, SolutionList, productList,dynamicInfo, highlyRecommended,} from '@/api/header'
import {newsList} from "@/api/home"
import {baseURL} from '@/utils/request'
export default {
  name: '',
  data () {
    return {
        baseURL:baseURL,
        proCateIdx:1, // 产品当前高亮导航
        caseList:[],//应用案例列表
        productList:[],//产品列表
        solutionsList:[],//解决方案列表
        dynamicInfoList:[],//动态咨询列表
        isShowNavIndex:null,
        recommendSolution:null,//解决方案重点推荐
        recommendInfo:{}
    }
  },
  created () {
    // 应用案例列表
    this.getCaseList()
    // 产品列表
    this.getproductList()
    // 解决方案
    this.getSolutionList()
    // 解决方案-重点推荐
    this.getHighlyRecommended()
    //动态咨询
    this.getDynamicInfo()
    this.getHomeShowDynamicInfo()
  },
  mounted () {

  },
  methods: {
    mEnter(index){
        this.isShowNavIndex = index
    },
    mLeave(){
        this.isShowNavIndex = null
    },
    pageJumps(path){
        this.isShowNavIndex = null
        this.$router.push({path})
    },
    mouseOver(val){
        this.proCateIdx=val
        this.getproductList()
    },
    // 产品列表
    getproductList(){
        let data = {
            id:this.proCateIdx
        }
        productList(data).then(res => {
            console.log(res,"产品头部数据")
            // console.log(data.id == 2?'软件产品列表':'硬件产品列表',res);
            if(res.code == 200){
                this.productList = []
                if(this.proCateIdx == 2){
                    res.data.forEach(val => {
                        val.productList.forEach(v => {
                            this.productList.push(v)
                        })
                    })
                    // console.log("this.productList @@@@@",this.productList);
                }else{
                    this.productList = res.data
                }
            }
        })
    },
    // 应用案例
    getCaseList(){
        let data = {
            pageNum:1,
            pageSize:1000,
        }
        CaseList(data).then(res => {
            console.log("res 应用案例列表",res);
            if(res.code == 200){
                this.caseList = res.rows
            }
        })
    },
    // 解决方案
    getSolutionList(){
        SolutionList().then(res=>{
            // console.log('解决方案',res);
            if(res.code == 200){
                this.solutionsList = res.rows
            }
        })
    },
    // 解决方案-重点推荐
    getHighlyRecommended(){
        highlyRecommended().then(res => {
            // console.log("res重点推荐",res);
            if(res.code == 200){
                this.recommendSolution = res.data
            }
        })
    },
    //动态咨询
    getDynamicInfo(){
        dynamicInfo().then(res=>{
            // console.log("Res 动态咨询",res);
            if(res.code == 200){
                this.dynamicInfoList = res.data
            }
        })
    },
    // 动态咨询 首页展示
    getHomeShowDynamicInfo(){
        newsList().then(res => {
            // console.log("res 动态咨询 首页展示",res);
            if(res.code == 200){
                if(res.rows && res.rows.length > 0){
                    this.recommendInfo = res.rows[0]
                }
            }
        })
    }
  },
}
</script>
<style lang="less" scoped>
.header{
     width: 100%;
    //width: 1920px;
    //max-width: 1920px;
    height: 92px;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    z-index: 999;
    // top: 0;
    // left: 0;

    .contact-number{
        display: flex;
        gap: 20px;
        // position: absolute;
        // top: 50%;
        // right: 0;
        .box{
            display: flex;
            align-items: center;
            gap: 10px;
            >div{
                display: flex;
                flex-direction: column;
                color: #097C1F;
                .label{
                    font-size: 14px;
                }
                .number{
                    font-size: 20px;
                }
            }
        }
    }

    .wrap{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo{
            height: 80px;
            cursor: pointer;
            img{
                height: 100%;
            }
        }
        .menu{
            display: flex;
            >div{
                margin-left: 30px;
                cursor: pointer;
            }

            // 菜单导航公共部分
            .dropdown {
                // margin: 0 20px;
                .dropdown-title {
                    position: relative;
                    font-size: 20px;
                    line-height: 92px;
                    // transition: all 1s ease-in-out;
                }

                .dropdown-content {
                    // 定位显示局域
                    width: 100%;
                    height: 525px;
                    background-color: #fff;
                    border-top:1px solid  rgba(237, 243, 245, 1);
                    box-shadow: 0px 16px 20px 0px rgba(34, 39, 41, 0.1);
                    visibility: hidden;
                    opacity: 1;
                    position: absolute;
                    left: 0;
                    top: 92px;
                    z-index: 99;
                    // overflow: hidden;
                    .dropdown-content-box {
                        border-radius: 4px;
                    }
                }
                .dropdown-content2{
                    height: 257px;
                }

                &.active {
                    .dropdown-title{
                        color: rgba(52, 156, 87, 1);
                        i{
                            transform: rotate(180deg);
                        }
                    }
                    .dropdown-content {
                        visibility: visible;
                        // opacity: 1;
                    }

                    .product-menu,.dynamic-menu,.aboutUs-menu{
                        margin-top: 0;
                        opacity: 1;
                        transition: margin .6s ,opacity  0.3s;
                    }

                    .solution-menu{
                        .text-left{
                            opacity: 1;
                            margin: 0;
                            transition: margin .6s ,opacity  0.3s;
                        }
                        .text-right{
                            >div{
                                opacity: 1;
                                margin: 0;
                                transition: margin .6s ,opacity  0.3s;
                            }
                        }
                    }
                }
            }

            // 产品下拉导航
            .product-menu{
                width: 1220px;
                height: 100%;
                margin: 0 auto;
                padding: 40px 0;
                box-sizing: border-box;
                display: flex;
                margin-top: -50px;
                opacity: 0.3;
                .product-left{
                    // width: 213px;
                    border-right: 1px solid  rgba(216, 224, 227, 1);
                    div{
                        padding-right: 23px;
                        color: rgba(31, 35, 41, 1);
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 21px;
                        border-right: 1px solid transparent;
                        white-space: nowrap;
                    }
                     .active{
                            color: rgba(52, 156, 87, 1);
                            border-right: 1px solid rgba(52, 156, 87, 1);
                        }
                }
                .product-soft{
                    flex: 1;
                    padding-left: 30px;
                    display: flex;
                    overflow-y: auto;

                    .product-list{
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        align-content: flex-start;
                        .product-list-item{
                            width: 260px;
                            height: 47px;
                            padding: 6px 8px;
                            border-radius: 4px;
                            margin-right: 20px;
                            margin-bottom: 17px;
                            box-sizing: border-box;
                            display: flex;
                            align-items: center;

                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                            img{
                                width: 32px;
                            }
                            .main{
                                margin-left: 7px;
                                .name{
                                    font-size: 14px;
                                    color: rgba(31, 35, 41, 1);
                                }
                                .desc{
                                    font-size: 12px;
                                    color: rgba(31, 35, 41, 0.6);
                                }
                            }
                        }
                    }
                    .new-list{
                        width: 48%;
                        display: flex;
                        .new-item{
                            flex-shrink: 0;
                            width: 248px;
                            height: 392px;
                            margin-right: 20px;
                            border-radius: 8px;
                            padding: 20px;
                            box-sizing: border-box;
                            border: 1px solid rgba(0, 0, 0, 0.1);
                            // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                            position: relative;
                            &:hover{
                                .detail-btn{
                                    color: rgba(28, 133, 232, 1);
                                    background: rgba(240, 246, 252, 1);
                                }
                            }
                            .name{
                                font-size: 16px;
                                color: rgba(31, 35, 41, 1);
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                padding: 0 50px 0 0;
                            }
                            .desc{
                                height: 17px;
                                margin-top: 2px;
                                font-size: 12px;
                                color: rgba(31, 35, 41, 0.8);
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            .new{
                                padding: 4px 8px;
                                background: rgba(255, 141, 26, 0.2);
                                color: rgba(255, 141, 26, 1);
                                font-size: 12px;
                                border-radius: 4px;
                                display: inline-block;
                                position: absolute;
                                top: 20px;
                                right: 26px;
                            }
                            .pic{
                                margin-top: 16px;
                                width: 100%;
                                height: 254px;
                                border-radius: 8px;
                                overflow: hidden;
                                img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                            .detail-btn{
                                margin-top: 20px;
                                display: inline-block;
                                height: 25px;
                                padding: 4px 12px;
                                box-sizing: border-box;
                                border-radius: 4px;
                                font-size: 12px;
                                color: rgba(31, 35, 41, 1);
                                span{
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                }

                .product-hard{
                    flex: 1;
                    padding-right: 26px;
                    display: flex;
                    justify-content: space-between;
                    overflow-y: auto;

                    .hard-items{
                        margin-left: 26px;
                        // height: 400px;
                        .label{
                            margin-left: 8px;
                            font-size: 18px;
                            color: rgba(52, 156, 87, 1);
                        }
                        .hard-pro{
                            margin-top: 8px;
                            width: 202px;
                            padding: 6px 8px;
                            box-sizing: border-box;
                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                            .name{
                                font-size: 14px;
                                color: rgba(31, 35, 41, 1);
                            }
                            .intro{
                                font-size: 12px;
                                color: rgba(31, 35, 41, 0.6);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }

            // 解决方案下拉导航
            .solution-menu{
                height: 100%;
                display: flex;
                background: rgba(242, 245, 250, 1);
                .left-box{
                    width: 50%;
                    background: rgba(242, 245, 250, 1);
                }
                .right-box{
                    width: 50%;
                    background: #fff;
                }

                // 内容盒子
                .text-wrap{
                    width: 1220px;
                    height: 100%;
                    display: flex;
                    box-sizing: border-box;
                    justify-content: space-between;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .text-left{
                    padding-top: 40px;
                    padding-right: 4%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    opacity: 0.3;
                    margin-top: -50px;

                    .label{
                        width: 250px;
                        font-size: 18px;
                        color: rgba(52, 156, 87, 1);
                        border-bottom: 1px solid rgba(216, 224, 227, 1);
                        padding: 5px 0;
                    }
                    .solution-list{
                        margin-top: 4px;
                        li{
                            display: flex;
                            align-items: center;
                            margin-top: 21px;
                            .serial{
                                width: 20px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: rgba(52, 156, 87, .15);
                                border-radius: 50%;
                                color: #fff;
                                font-size: 16px;
                            }
                            .name{
                                font-size: 16px;
                                color: rgba(52, 156, 87, 1);
                                margin: 0 8px;
                                width: 200px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            i{
                                font-size: 14px;
                            }
                            &:hover{
                                .serial{
                                background: rgba(52, 156, 87, .25);
                                color: rgba(43, 130, 229, 1);
                                }
                                .name{
                                color: rgba(52, 156, 87, 1);
                                }
                                i{
                                color: rgba(52, 156, 87, 1);
                                }
                            }
                        }
                    }
                    .pic-text{
                        width: 260px;
                        height: 392px;
                        padding: 20px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                        position: relative;
                        // opacity: 0.3;
                        margin-top: 100px;
                        &:hover{
                            .detail-btn{
                                color: rgba(28, 133, 232, 1);
                                background: rgba(240, 246, 252, 1);
                            }
                        }
                        .name{
                            padding: 0 50px 0 0;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 16px;
                            color: rgba(31, 35, 41, 1);
                        }
                        .desc{
                            margin-top: 2px;
                            font-size: 12px;
                            color: rgba(31, 35, 41, 0.8);
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }
                        .new{
                            padding: 4px 8px;
                            background: rgba(255, 141, 26, 0.2);
                            color: rgba(255, 141, 26, 1);
                            font-size: 12px;
                            border-radius: 4px;
                            display: inline-block;
                            position: absolute;
                            top: 20px;
                            right: 26px;
                        }
                        .pic{
                            margin-top: 16px;
                            width: 100%;
                            height: 254px;
                            border-radius: 8px;
                            overflow: hidden;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .detail-btn{
                            margin-top: 20px;
                            display: inline-block;
                            height: 25px;
                            padding: 4px 12px;
                            box-sizing: border-box;
                            border-radius: 4px;
                            font-size: 12px;
                            color: rgba(31, 35, 41, 1);
                            span{
                                margin-right: 7px;
                            }
                        }
                    }
                }
                .text-right{
                    flex: 1;
                    height: 100%;
                    padding: 40px 0 0 50px;
                    box-sizing: border-box;
                    background: #fff;

                    >div:first-child{
                        height: 100%;
                        opacity: 0.3;
                        margin-top: -50px;

                        // 隐藏滚动条
                        ::-webkit-scrollbar {
                            width: 0 !important;
                            height: 0;
                        }

                        .label{
                            font-size: 18px;
                            color: rgba(52, 156, 87, 1);
                            border-bottom: 1px solid rgba(216, 224, 227, 1);
                            padding: 5px 0;
                        }
                        .case-list{
                            margin-top: 16px;
                            display: flex;
                            gap: 20px 32px;
                            flex-wrap: wrap;
                            align-content: flex-start;
                            height: calc(100% - 90px);
                            overflow-y: auto;

                            .item{
                                width: 265px;
                                padding: 6px 10px;
                                border-radius: 4px;
                                box-sizing: border-box;
                                background: rgba(245, 247, 250, 0.6);
                                display: flex;
                                align-items: center;

                                &:hover{
                                    background: rgba(52, 156, 87, .15);
                                }
                                img{
                                    width: 32px;
                                    height: 32px;
                                    object-fit: cover;
                                }
                                .main{
                                    margin-left: 7px;
                                    .name{
                                        width: 200px;
                                        font-size: 15px;
                                        color: rgba(31, 35, 41, 1);
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                    .desc{
                                        font-size: 12px;
                                        color: rgba(31, 35, 41, 0.6);
                                        width: 200px;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }

                }
            }

            // 动态资讯菜单
            .dynamic-menu{
                width: 1220px;
                height: 100%;
                margin: 0 auto;
                padding-top: 40px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                margin-top: -50px;
                opacity: 0.3;

                .menu-item{
                    width: 250px;
                    height: 100%;
                    margin-right: 57px;
                    position: relative;
                    .label{
                        color: rgba(52, 156, 87, 1);
                        font-size: 18px;
                        padding: 5px 0;
                        border-bottom: 1px solid rgba(216, 224, 227, 1);
                    }
                    .list{
                        height: 400px;
                        overflow: hidden;
                        margin-left: -10px;
                        max-height: 350px;
                        li{
                            width: 260px;
                            padding: 8px;
                            box-sizing: border-box;
                            color: rgba(31, 35, 41, 1);
                            margin-top: 16px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            &:hover{
                                background: rgba(52, 156, 87, .15);
                            }
                        }
                    }
                    .look-more{
                        width: 88px;
                        padding: 4px 8px 4px 8px;
                        box-sizing: border-box;
                        text-align: center;
                        border-radius: 4px;
                        color: rgba(52, 156, 87, 1);
                        font-weight: 500;
                        font-size: 14px;
                        position: absolute;
                        bottom: 40px;
                        &:hover{
                            background: rgba(52, 156, 87, .15);
                        }
                    }
                }

                .pic-text{
                    width: 260px;
                    height: 392px;
                    padding: 20px;
                    box-sizing: border-box;
                    border-radius: 8px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    // box-shadow: 0px 8px 24px 0px rgba(31, 35, 41, 0.1);
                    position: relative;
                    &:hover{
                        .detail-btn{
                            color: rgba(52, 156, 87, 1);
                            background: rgba(52, 156, 87, .15);
                        }
                    }
                    .name{
                        font-size: 16px;
                        color: rgba(31, 35, 41, 1);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding: 0 50px 0 0;
                    }
                    .desc{
                        margin-top: 2px;
                        font-size: 12px;
                        color: rgba(31, 35, 41, 0.8);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .new{
                        padding: 4px 8px;
                        background: rgba(255, 141, 26, 0.2);
                        color: rgba(255, 141, 26, 1);
                        font-size: 12px;
                        border-radius: 4px;
                        display: inline-block;
                        position: absolute;
                        top: 20px;
                        right: 26px;
                    }
                    .pic{
                        margin-top: 16px;
                        width: 100%;
                        height: 254px;
                        border-radius: 8px;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .detail-btn{
                        margin-top: 20px;
                        display: inline-block;
                        height: 25px;
                        padding: 4px 12px;
                        box-sizing: border-box;
                        border-radius: 4px;
                        font-size: 12px;
                        color: rgba(31, 35, 41, 1);
                        span{
                            margin-right: 7px;
                        }
                    }

                }
            }

            // 关于我们
            .aboutUs-menu{
                width: 1220px;
                margin: 0 auto;
                padding-top: 34px;
                display: flex;
                flex-wrap: wrap;
                align-content: flex-start;
                margin-top: -50px;
                opacity: 0.3;

                .aboutUs-menu-item{
                    // width: 140px;
                    flex: 0 0 140px;
                    height: 36px;
                    padding: 8px;
                    box-sizing: border-box;
                    border-radius: 4px;
                    margin-right: 180px;
                    margin-bottom: 32px;
                    display: flex;
                    align-items: center;
                    &:hover{
                        background: rgba(52, 156, 87, .15);
                    }
                    &:nth-child(4n){
                        margin-right: auto;
                    }
                    img{
                        width: 24px;
                        height: 24px;
                    }
                    span{
                        margin-left: 2px;
                        font-size: 18px;
                        color: rgba(33, 39, 49, 1);
                    }
                }
            }
        }
    }
}
</style>
