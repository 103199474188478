<template>
    <div class='move-tabs'>
      <div class='tabs-wrapper' ref='tabsWrapper'>
        <ul ref='tab' >
          <li  v-for='(item, index) in tabs' :key='index'>
            <div class="tab_item" :class="{'active' : indicatorIndex == index}" @click="setActiveItem(index)">
                <div class="year">{{ item.year }}</div>
                <div class="icon">
                    <div class="box">
                        <div class="dot"></div>
                    </div>
                    <div class="line"></div>
                </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <script>
    import BScroll from 'better-scroll'
   
    export default {
      props: {
        data: Array,
      },
      data() {
        return {
          tabs: [],
          tabHeihgt: 60,
          indicatorIndex:0,
        }
      },
      watch:{
        data(newValue){
            this.$nextTick(() => {
                console.log(this.data)
                this.tabs = this.data
                this._initMenu()
            })
        }
      },
      mounted() {
        
      },
      methods: {
        setActiveItem(index){
            this.$emit('setActiveItem',index)
            this.indicatorIndex = index
        },
        _initMenu() {
          const tabHeihgt = this.tabHeihgt
          const height = this.tabs.length * tabHeihgt
          this.$refs.tab.style.height = `${height}px`
          this.$nextTick(() => {
            if (!this.scroll) {
              this.scroll = new BScroll(this.$refs.tabsWrapper, {
                scrollY: true,
              })
            } else {
              this.scroll.refresh()
            }
          })
        },
      },
    }
  </script>
  <style scoped lang="less">
    .move-tabs {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 4;
      width: 300px;
    }
    .tabs-wrapper {
      height: 300px;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      ul{
        padding: 10px 0;
      }
      
      .tab_item{
        height: 60px;
        cursor: pointer;
        display: flex;
        &.active{
            .year{
                background: url('@/assets/images/aboutUs_details/timeBg.png') no-repeat;
                background-size: 100% 100%;
                color: #fff;
            }
            .icon{
                .box{
                    background: rgba(52, 156, 87, 1);
                    .dot{
                        background: #fff;
                    }
                }
            }
        }
        .year{
            width: 79px;
            height: 40px;
            line-height: 40px;
            margin: -8px 6px 0 0;
            padding: 0 0 0 8px;
            box-sizing: border-box;
            font-size: 20px;
            color: rgba(88, 91, 92, 1);
            white-space: nowrap;
            overflow: hidden;
        }
        .icon{
            display: flex;
            flex-direction: column;
            align-items: center;
            .box{
                width: 24px;
                height: 24px;
                border-radius: 4px;
                background: rgba(190, 195, 203, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                transform: rotate(-45deg);
                .dot{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                }
            }
            .line{
                width: 1px;
                height: 33px;
                background: rgba(166, 166, 166, 0.8);
            }
        }
      }
        .indicator{
            .indicator_item{
                height: 60px;
                cursor: pointer;
                display: flex;
                &.active{
                    .year{
                        background: url('@/assets/images/aboutUs_details/timeBg.png') no-repeat;
                        background-size: 100% 100%;
                        color: #fff;
                    }
                    .icon{
                        .box{
                            background: rgba(42, 130, 228, 1);
                            .dot{
                                background: #fff;
                            }
                        }
                    }
                }
                .year{
                    width: 79px;
                    height: 40px;
                    line-height: 40px;
                    margin: -8px 6px 0 0;
                    padding: 0 0 0 8px;
                    box-sizing: border-box;
                    font-size: 20px;
                    color: rgba(88, 91, 92, 1);
                    white-space: nowrap;
                    overflow: hidden;
                }
                .icon{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .box{
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                        background: rgba(190, 195, 203, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transform: rotate(-45deg);
                        .dot{
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                        }
                    }
                    .line{
                        width: 1px;
                        height: 33px;
                        background: rgba(166, 166, 166, 0.8);
                    }
                }
            }
        }
    }
  </style>