<template>
    <div class="dynamicInfo " >
        <div class="dynamicInfo_container">
            <Banner />
            <div class="tab wrap">
                <div v-for="item in types" :key="item.id" :class="{active : tabIndex == item.id}" @click="change(item.id)">{{ item.name }}</div>
                <!-- <div :class="{active : tabIndex == 2}" @click="change(2)">行业动态</div>
                <div :class="{active : tabIndex == 3}" @click="change(3)">专家问题</div> -->
            </div>
            <div class="menu wrap">
                <div class="menu_item" v-for="item in list" :key="item.id" @click="pageJumps(item.id)">
                    <img :src="baseURL+item.newsPic" alt="">
                    <div>{{ item.newsTitle }}</div>
                </div>
            </div>
            <div class="pagination"> 
                <el-pagination
                    background
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page.sync="queryParams.pageNum"
                    :page-size.sync="queryParams.pageSize"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
//         
import {dynamicInfoPage} from '@/api/dynamicInfo'
import {dynamicInfo} from '@/api/header'
import Banner from '@/components/Banner.vue'
import {baseURL} from '@/utils/request'
    export default {
        name:'dynamicInfo',
        components:{Banner},
        data(){
            return{
                baseURL,
                tabIndex:1,
                total:0,
                queryParams:{
                    pageSize:9,
                    pageNum:1,
                },
                types:[],
                list:[],
            }
        },
        watch:{
            '$route'(newValue){
                this.change(newValue.query.id)
            }
        },
        created(){
            this.tabIndex = this.$route.query.id
            
        },
        mounted(){
            // 动态咨询
            this.getDynamicInfo()
            
        },  
        methods:{
            change(id){
                this.tabIndex = id
                this.queryParams={pageSize:9, pageNum:1,}

                // 动态咨询分页
                this.getDynamicInfoPage()
            },
            pageJumps(id){
                this.$router.push(`/dynamicDetails?id=${id}&typeId=${this.tabIndex}`)
            },
            // 动态咨询分页
            getDynamicInfoPage(){
                let para=this.queryParams
                para.newsType=this.tabIndex
                dynamicInfoPage(para).then(res => {
                    console.log("res 动态咨询分页",res);
                    if(res.code == 200){
                        this.list = res.rows
                        this.total = res.total
                    }
                })
            },
            // 动态咨询
            getDynamicInfo(){
                dynamicInfo().then(res=>{
                    // console.log("res 动态咨询类型",res);
                    if(res.code == 200){
                        this.types = res.data
                        // 动态咨询分页
                        this.getDynamicInfoPage()
                    }
                })
            },

            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                this.queryParams.pageNum=val
                this.getDynamicInfoPage()
            },
        }
    }
</script>

<style lang="less" scoped>
.dynamicInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    .dynamicInfo_container{
        width: 1920px;
        padding: 0 0 72px 0;
        background: #F0F2F7;

    }
    .tab{
        // width: 1220px;
        height: 120px;
        border-radius: 16px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 12px 0px rgba(29, 34, 38, 0.1);
        margin: 72px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        >div{
            width: 132px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 115px 0 0;
            font-size: 24px;
            color: rgba(60, 64, 70, 1);
            border-radius: 4px;
            cursor: pointer;
            &.active{
                background: rgba(52, 156, 87, 1);
                color: #fff;
            }
        }
        >div:last-child{
            margin: 0;
        }
    }

    .menu{
        display: flex;
        flex-wrap: wrap;
        .menu_item{
            width: 390px;
            height: 292px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(0, 0, 0, 0.1);            
            margin: 0 20px 20px 0 ;
            overflow: hidden;
            cursor: pointer;
            img{
                width: 390px;
                height: 222px;
                vertical-align: top;
            }
            div{
                font-size: 16px;
                color: rgba(31, 35, 41, 1);
                margin-top: 24px;
                text-align: center;
            }
            &:hover{
                box-shadow: 0px 2px 32px 4px rgba(41, 54, 66, 0.1);
            }
        }
        .menu_item:nth-child(3n){
            margin-right: 0;
        }
    }
    .pagination{
        margin: 52px 0 0 0;
        text-align: center;
        // /deep/.el-pagination.is-background .el-pager li:not(.disabled):hover{
        //     color: rgba(52, 156, 87, 1);
        // }
        /deep/.el-pagination{
            .el-pager{
                li{
                    min-width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border: 1px solid rgba(0, 0, 0, 0.2);
                    background: transparent;
                    font-size: 16px;
                    &:hover{
                        color: rgba(52, 156, 87, 1);
                    }
                    &.active{
                        color: #fff !important;
                        background: rgba(52, 156, 87, 1);
                    }
                }
            }
            button{
                min-width: 40px;
                height: 40px;
                line-height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.2);
                background: transparent;
                i{
                    font-size: 16px;
                }
            }
        }
    }
    
}
</style>